import { SignService } from "@/Services/SignService";
import { actionSignature } from "@/Methods/ActionSignature";
import {
  AutoexchangeSignatures,
} from "@/modules/Signatures";
import { getDeepSortedPropsObj } from '@/utils/common'

export default class RequestOfferModel {
  constructor({
    userId,
    walletId,
    accountId,
    ownedAmount,
    ownedCurrency,
    wantedAmount,
    wantedCurrency,
    exchangeRate,
    minMatchAmount,
    country,
    city,
    requisites_hash,
    response_offer_op_id,
    banknotes,
    type,
    payment_types,
    operation_type,
    payment_reason,
    additional_info,
    currencies_order
  }) {
    this.exchange_rate = exchangeRate || '0'
    this.owned_amount = ownedAmount
    this.owned_currency = ownedCurrency
    this.type = type
    this.user_account_info = {
      account_id: accountId,
      user_id: userId,
      wallet_id: walletId,
    };
    this.wanted_amount = wantedAmount
    this.wanted_currency = wantedCurrency
    this.banknotes = banknotes
    this.city = city
    this.country = country
    this.min_match_amount = minMatchAmount || '0'
    this.requisites_hash = requisites_hash || null
    this.response_offer_op_id = response_offer_op_id || ''
    this.payment_types = payment_types
    this.operation_type = operation_type
    this.payment_reason = payment_reason
    this.additional_info = additional_info
    this.currencies_order = currencies_order
  }

  getSignedRequest(key, userId) {
    // const sortedData = {}
    // // Для подписи используются отсортированные свойства в объекте
    // Object.keys(this).sort((a, b) => a.localeCompare(b)).forEach(prop => {
    //   sortedData[prop] = this[prop]
    // })
    const data = getDeepSortedPropsObj(this)
    let signService = new SignService(key, userId)
    let actionId = actionSignature(
      AutoexchangeSignatures.channel,
      AutoexchangeSignatures.chaincode,
      AutoexchangeSignatures.action.addAutoexchange
    )
    return signService.createSignedRequest(actionId, data)
  }
}
