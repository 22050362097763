import { isFirstWordEarlier } from '@/utils/common'

export default function (data = {}) {
  const {
    ordinal,
    rule_type,
    status,
    user_turn,
  } = data
  return {
    ordinal,
    rule_type,
    status,
    user_turn,
  }
}
