export default function (data = {}) {
  const {
    commission_percent,
    customer_type,
    max,
    min,
    partner_code,
    process_type
  } = data
  return {
    commission_percent: commission_percent || '',
    customer_type: customer_type || '',
    max: max || '',
    min: min || '',
    partner_code: partner_code || '',
    process_type: process_type || '',
  }
}
