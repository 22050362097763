import { SignService } from '@/Services/SignService'
import { actionSignature } from '@/Methods/ActionSignature'
import { TransactionSignatures } from '@/modules/Signatures'
import { getDeepSortedPropsObj } from '@/utils/common'
import { NewOutUser } from '@/DataModels/NewExchangeWithUser'
import { Amount } from '@/DataModels/Amount'

export default class CommissionLockRequest {
  constructor ({ amount, account, userId, wallet, currency_type = 'lion', process_type }) {
    this.amount = new Amount(amount, currency_type)
    this.out_user = new NewOutUser(account, userId, wallet)
    this.process_type = process_type
  }

  getSignedRequest (key, userId) {
    const data = getDeepSortedPropsObj(this)
    let signService = new SignService(key, userId)
    let actionId = actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockCommission)
    return signService.createSignedRequest(actionId, data)
  }
}
