import { CURRENCY_SHORT_CODES } from '@/constants/auto-exchange'

export default function (data) {
  const {
    currencies_order,
    first_currency,
    operation_type,
    second_currency,
    step,
    users_can_buy,
    users_can_sell
  } = data
  const result = {
    currencies_order,
    first_currency,
    operation_type,
    second_currency,
    step,
    users_can_buy,
    users_can_sell
  }
  result.firstCurrencyShort = CURRENCY_SHORT_CODES[first_currency]
  result.secondCurrencyShort = CURRENCY_SHORT_CODES[second_currency]
  result.pair = `${result.firstCurrencyShort}/${result.secondCurrencyShort}`
  return result
}
