import { getDeepSortedPropsObj } from '@/utils/common'
import sha256 from 'js-sha256'

export default class P2PDealAddressModel {
  constructor (data) {
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
  }

  get hash () {
    const {
      city,
      country,
      street,
      time
    } = this
    const addressData = getDeepSortedPropsObj({
      city,
      country,
      street,
      time
    })
    return sha256(JSON.stringify(addressData))
  }
}
