import BaseSignModel from '@/models/BaseSignModel'
import { AutoexchangeSignatures } from '@/modules/Signatures'

export default class P2PDealStatus extends BaseSignModel{
  constructor (data) {
    const {
      channel,
      chaincode,
      action: { addAeDealStatus: action }
    } = AutoexchangeSignatures
    super({ channel, chaincode, action, data })
  }
}
