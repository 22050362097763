import ForeignAccount from '@/components/P2P/P2PAddForm/Requisites/ForeignAccount.vue'
import CashSelector from '@/components/P2P/P2PAddForm/Requisites/CashSelector.vue'
import { defineAsyncComponent } from 'vue'

export default class RequisitesModel {
  constructor (requisitesType) {
    this.type = requisitesType
    this.fields = REQUISITES_BY_TYPE[requisitesType].fields
    this.component = REQUISITES_BY_TYPE[requisitesType].component
  }

}

export const REQUISITES_TYPES = {
  CARD: 'CardAmount',
  CASH: 'Cash',
  FOREIGN_ACCOUNT: 'AccountAmount'
}

export const REQUISITES_TYPES_FOR_REQUEST = {
  // [REQUISITES_TYPES.BANK_TRANSFER]: 'account_amount',
  [REQUISITES_TYPES.FOREIGN_ACCOUNT]: 'account_amount',
  [REQUISITES_TYPES.CARD]: 'card_amount',
  [REQUISITES_TYPES.CASH]: 'cash'
}

export const REQUISITES = [
  {
    label: 'p2p_requisites_card_title',
    value: REQUISITES_TYPES.CARD
  },
  {
    label: 'p2p_requisites_account_title',
    value: REQUISITES_TYPES.FOREIGN_ACCOUNT
  },
  {
    label: 'p2p_requisites_cash_title',
    value: REQUISITES_TYPES.CASH
  },
]

export const REQUISITES_BY_TYPE = {
  [REQUISITES_TYPES.CARD]: {
    type: 'table',
    component: 'CardRequisites',
    fields: [
      {
        label: 'p2p_requisites_card_bank',
        propName: 'bank'
      },
      {
        label: 'p2p_requisites_card_number',
        propName: 'card_number'
      },
      {
        label: 'p2p_requisites_desc',
        propName: 'description'
      },
    ]
  },
  [REQUISITES_TYPES.FOREIGN_ACCOUNT]: {
    type: 'other',
    component: defineAsyncComponent(() => import('@/components/P2P/P2PAddForm/Requisites/ForeignAccount.vue')),
    fields: [
      {
        label: 'Название компании',
        propName: 'company_name'
      },
      {
        label: 'Адрес',
        propName: 'address'
      },
      {
        label: 'Номер счёта',
        propName: 'account_number'
      },
      {
        label: 'Название банка',
        propName: 'bank'
      },
      {
        label: 'Адрес банка',
        propName: 'bank_address'
      },
      {
        label: 'SWIFT/БИК',
        propName: 'swift_code'
      },
      {
        label: 'Имя держателя счёта',
        propName: 'account_holder_name'
      },
      {
        label: 'Страна банка',
        propName: 'bank_country'
      },
    ]
  },
  // [REQUISITES_TYPES.BANK_TRANSFER]: {
  //   type: 'table',
  //   component: 'BankTransferRequisites',
  //   fields: [
  //     {
  //       label: 'p2p_requisites_card_bank',
  //       propName: 'bank'
  //     },
  //     {
  //       label: 'p2p_requisites_bank_bik',
  //       propName: 'bik'
  //     },
  //     {
  //       label: 'p2p_requisites_bank_korr',
  //       propName: 'korr_account_number'
  //     },
  //     {
  //       label: 'p2p_requisites_bank_acc',
  //       propName: 'account_number'
  //     },
  //     {
  //       label: 'p2p_requisites_bank_fio',
  //       propName: 'fio'
  //     },
  //     {
  //       label: 'p2p_requisites_desc',
  //       propName: 'description'
  //     }
  //   ]
  // },
  [REQUISITES_TYPES.CASH]: {
    type: 'other',
    component: CashSelector,
    fields: [
      {
        label: 'p2p_requisites_cash_address',
        propName: 'address'
      },
      {
        label: 'p2p_requisites_desc',
        propName: 'description'
      }
    ]
  },
}
