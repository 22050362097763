import moment from 'moment-mini'
import { CURRENCY_SHORT_CODES, FIAT_CURRENCY_NAMES } from '@/constants/auto-exchange'
import { DEAL_STATUS_LABEL, FORM_TYPE_LABELS, FORM_TYPE_NAMES } from '@/constants/P2P'
import Decimal from 'decimal.js'
import P2PDealAddressModel from '@/models/P2P/P2PDealAddressModel'

export default class {
  constructor (data) {
    const {
      buy_user_id,
      sell_user_id,
      operation_type,
      payment_reason,
      country,
      city,
      buy_amount,
      sell_amount,
      buy_user_reserve,
      sell_user_reserve,
      payment_types,
      operation_id,
      offer_operation_id,
      match_operation_id,
      statuses,
      timestamp,
      requisites,
      rule_type,
      addresses,
      address_hashes,
      accepted_address_hash,
      accepted_payment_type,
      banknotes,
      agreement_docs,
      act_docs,
      accepted_agreement,
      accepted_act,
      buy_product_details,
      buy_requisites,
      sell_product_details,
      sell_requisites,
      sell_offer_operation_id,
    } = data || {}
    this.sell_offer_operation_id = sell_offer_operation_id
    this.buy_product_details = buy_product_details
    this.buy_requisites = buy_requisites
    this.sell_product_details = sell_product_details
    this.sell_requisites = sell_requisites
    this.agreement_docs = agreement_docs || []
    this.act_docs = act_docs || []
    this.accepted_agreement = accepted_agreement
    this.accepted_act = accepted_act
    this.operation_id = operation_id
    this.offer_operation_id = offer_operation_id
    this.timestamp = timestamp
    this.country = country
    this.city = city
    this.buy_user_id = buy_user_id
    this.sell_user_id = sell_user_id
    this.operation_type = operation_type
    this.payment_reason = payment_reason
    this.buy_amount = buy_amount
    this.sell_amount = sell_amount
    this.buy_user_reserve = buy_user_reserve
    this.sell_user_reserve = sell_user_reserve
    this.payment_types = payment_types
    this.match_operation_id = match_operation_id
    this.status = statuses
    this.requisites = requisites
    this.banknotes = banknotes
    this._time = moment.unix(this.timestamp).format('DD/MM/YY HH:mm:ss')

    this.buyAmount = this.buy_amount.amount
    this.buyCurrencyShort = CURRENCY_SHORT_CODES[this.buy_amount.currency]
    this.sellAmount = this.sell_amount.amount
    this.sellCurrencyShort = CURRENCY_SHORT_CODES[this.sell_amount.currency]
    this.statusLabel = DEAL_STATUS_LABEL[this.status.at(-1)]
    this.typeLabel = FORM_TYPE_LABELS[this.operation_type]
    this.rule_type = rule_type
    this.addresses = addresses?.map(i => new P2PDealAddressModel(i)) || []
    this.address_hashes = address_hashes
    this.accepted_address_hash = accepted_address_hash
    this.accepted_payment_type = accepted_payment_type
    this.isSellingExternalFiat = this.buy_amount.currency.includes('RUB')
    if (this.isSellingExternalFiat) {
      this.fiatBuyAmount = this.buyAmount
      this.fiatBuyCurrency = this.buyCurrencyShort
      this.fiatSellAmount = this.sellAmount
      this.fiatSellCurrency = this.sellCurrencyShort
      this.buyer = this.sell_user_id
      this.seller = this.buy_user_id
      this.buyerReserveAmount = this.sell_user_reserve.amount
      this.buyerReserveCurrency = CURRENCY_SHORT_CODES[this.sell_user_reserve.currency]
      this.sellerReserveAmount = this.buy_user_reserve.amount
      this.sellerReserveCurrency = CURRENCY_SHORT_CODES[this.buy_user_reserve.currency]
      this.buyProductDetails = this.buy_product_details
      this.buyRequisites = this.buy_requisites
      this.sellProductDetails = this.sell_product_details
      this.sellRequisites = this.sell_requisites
    } else {
      this.fiatBuyAmount = this.sellAmount
      this.fiatBuyCurrency = this.sellCurrencyShort
      this.fiatSellAmount = this.buyAmount
      this.fiatSellCurrency = this.buyCurrencyShort
      this.buyer = this.buy_user_id
      this.seller = this.sell_user_id
      this.buyerReserveAmount = this.buy_user_reserve.amount
      this.buyerReserveCurrency = CURRENCY_SHORT_CODES[this.buy_user_reserve.currency]
      this.sellerReserveAmount = this.sell_user_reserve.amount
      this.sellerReserveCurrency = CURRENCY_SHORT_CODES[this.sell_user_reserve.currency]
      this.buyProductDetails = this.sell_product_details
      this.buyRequisites = this.sell_requisites
      this.sellProductDetails = this.buy_product_details
      this.sellRequisites = this.buy_requisites
    }
  }

}
