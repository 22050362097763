import { isFirstWordEarlier } from '@/utils/common'

export default function (data = {}) {
  const {
    step,
    first_currency,
    second_currency,
    users_can_sell,
    users_can_buy,
    operation_type
  } = data
  const fWord = first_currency || ''
  const sWord = second_currency || ''
  const currencies_order = isFirstWordEarlier(fWord, sWord)
  return {
    currencies_order,
    step: step || '',
    first_currency: fWord,
    second_currency: sWord,
    users_can_sell: users_can_sell || [],
    users_can_buy: users_can_buy || [],
    operation_type: operation_type || ''
  }
}
