export default function (data = {}  ) {
  const {
    decimals,
    name,
    symbol,
    token_type
  } = data
  return {
    decimals: decimals || '',
    name: name || '',
    symbol: symbol || '',
    token_type: token_type || '',
  }
}
